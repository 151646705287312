import React from 'react';
import { Typography } from '../../components/atoms';

const getDocument = (filePath: string) => window.open(filePath);

export const reviewSchema = {
  rightToCancel: {
    heading: 'Your right to cancel',
    body: (
      <>
        <Typography>
          You can get a refund on your policy if it&apos;s cancelled within the 14 day cooling off period. To cancel,
          you can call us on 0345 246 1558 or return the documents within 14 days of receipt to The Wharf,
          Neville Street, Leeds, LS1 4AZ. We will refund the premium in full, if you&apos;ve not begun your trip or
          made a claim during the first 14 days.
        </Typography>
      </>
    ),
  },
  complaints: {
    heading: 'Complaints',
    body: (
      <>
        <Typography>
          If you&apos;re not happy about something, please let us know. If you have a complaint, the quickest way
          to get in touch is to call us on 08000510636.
        </Typography>
        <Typography>
          You can also write to us at the address shown in your policy document, which gives more details
          about how to make a complaint.
        </Typography>
        <Typography>
          If we can&apos;t resolve the issue, you can take the matter to the Financial Ombudsman Service (FOS).
          Their address is: The Financial Ombudsman Service, Exchange Tower, London E14 9SR. Phone:
          0800 023 4567 or 0300 123 9123. You can visit the FOS website at{' '}
          <a href="https://www.financial-ombudsman.org.uk/" target="_blank" rel="noreferrer">
            www.financial-ombudsman.org.uk
          </a>.
        </Typography>
        <Typography>Keep in mind, your cover is subject to the standard policy wording.</Typography>
      </>
    ),
  },
  financialCompensation: {
    heading: 'Financial Services Compensation Scheme',
    body: (
      <>
        <Typography>
          General insurance claims are covered by the Financial Services Compensation Scheme. Full details
          of the cover available can be found at <a href="https://www.fscs.org.uk/" target="_blank" rel="noreferrer">fscs.org.uk</a>.
          U K Insurance Limited is a member of this scheme. 
        </Typography>
      </>
    ),
  },
  informationUsage: {
    heading: "How we'll use your information",
    body: (
      <>
        <Typography>
          We will use your information and that of any other people named on your policy to assess your
          breakdown cover application and the terms on which cover may be offered. We may exchange your
          information with other insurers, reinsurers or other carefully selected third parties in order to
          manage your policy and offer you the best possible service. We will also obtain information about
          you from credit reference agencies to check your credit status and identity. The agencies record our
          enquiries but your credit standing will not be affected.
        </Typography>
        <Typography>
          Green Flag breakdown policies are underwritten by U K Insurance Limited (UKI). The information you
          give us may be used by owned brands within UKI to submit to and check your details with fraud
          prevention agencies and other organisations. If you provide false or inaccurate information, we will
          record this. We and other organisations may use and search these records to prevent fraud and
          money laundering.
        </Typography>
        <Typography>
          If you would like full details on how we will use your information, please refer to our{' '}
          <a href="https://u-k-insurance.co.uk/brands-policy.html" target="_blank" rel="noreferrer">
            privacy policy
          </a>.
        </Typography>
      </>
    ),
  },
  englishLawApplies: {
    heading: 'English law applies',
    body: (
      <>
        <Typography>
          You and we may choose which law will apply to this policy. Unless both parties agree otherwise
          English law will apply. We have supplied this policy and other information to you in English and we
          will continue to communicate with you in English.
        </Typography>
      </>
    ),
  },
};

export default reviewSchema;
